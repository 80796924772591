'use client';
import React, { FC } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger } from '@radix-ui/react-select';
import { usePathname, useRouter } from '@/i18n/routing';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../ui/dropdown-menu';
import { cn } from '@/lib/utils';
import { LuChevronDown } from 'react-icons/lu';

type Props = {
    locale: string;
};

const LangSwitcher: FC<Props> = ({ locale }) => {
    const router = useRouter();
    const pathname = usePathname();

    const handleChange = (v: 'cs' | 'en' | undefined) => {
        console.log(v);
        router.push(pathname, { locale: v });
    };

    const flagCode = (lang: string) => {
        switch (lang) {
            case 'cs':
                return 'CZ';
            case 'en':
                return 'US';
        }
    };

    return (
        <DropdownMenu>
            <DropdownMenuTrigger className="flex place-items-center items-center gap-2" id="langswitcher">
                <img alt="United States" src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${flagCode(locale)}.svg`} className="aspect-video size-8" />
                <LuChevronDown />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="flex flex-row items-center gap-4 bg-background p-4">
                <DropdownMenuItem
                    onClick={() => handleChange('cs')}
                    className={cn({
                        'bg-muted': locale === 'cs'
                    })}>
                    <img alt="United States" src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/CZ.svg`} className="aspect-video size-8" />
                </DropdownMenuItem>
                <DropdownMenuItem
                    onClick={() => handleChange('en')}
                    className={cn({
                        'bg-muted': locale === 'en'
                    })}>
                    <img alt="United States" src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg`} className="aspect-video size-8" />
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );
};

export default LangSwitcher;
